<template>
  <v-main class="grey lighten-3">
    <v-container>
      <v-row>
        <v-col
            cols="12"
            lg="4"
            md="4"
        >
          <create-topic :course="course" @updated="loadCourse"></create-topic>
          <v-divider></v-divider>
          <create-coupons v-if="course" :course="course"></create-coupons>
        </v-col>

        <v-col>
          <v-sheet
              min-height="70vh"
              rounded="lg"
              max-width="750"
          >
            <v-card
                class="mx-auto"
            >
              <v-toolbar flat amber>
                <v-btn icon exact :to="{name: 'InsertVideo',params:{courseId:$route.params.courseId}}">
                  <v-icon>mdi-video</v-icon>
                </v-btn>

                <v-btn icon exact :to="{name: 'InsertExam',params:{courseId:$route.params.courseId}}">
                  <v-icon>mdi-newspaper</v-icon>
                </v-btn>

                <v-btn icon exact :to="{name: 'InsertWrittenExam',params:{courseId:$route.params.courseId}}">
                  <v-icon>mdi-book-open</v-icon>
                </v-btn>

                <v-btn icon exact :to="{name: 'InsertNote',params:{courseId:$route.params.courseId}}">
                  <v-icon>mdi-note-outline</v-icon>
                </v-btn>

                <v-btn icon exact :to="{name: 'InsertPdf',params:{courseId:$route.params.courseId}}">
                  <v-icon>mdi-file-pdf</v-icon>
                </v-btn>

                <v-btn icon exact :to="{name: 'InsertAudio',params:{courseId:$route.params.courseId}}">
                  <v-icon>mdi-music</v-icon>
                </v-btn>

                <v-spacer>
                </v-spacer>
                <h3>{{ course.title }}</h3>
              </v-toolbar>

              <v-divider></v-divider>

              <v-card-text style="min-height: 200px;">
                <router-view :course="course" :key="$route.fullPath" @updated="loadCourse"></router-view>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import CreateTopic from './CreateTopic.vue'
import axios from 'axios'
import CreateCoupons from "./CreateCoupons";

export default {
  components: {
    CreateTopic,
    CreateCoupons,
  },
  data: () => ({
    course: '',
  }),
  methods: {
    loadCourse() {
      const url = 'admin/courses/' + this.$route.params.courseId;
      axios.get(url).then((response) => {
        this.course = response.data;
      })
    }
  },
  mounted() {
    this.loadCourse();
  }
}
</script>