<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-dialog
            v-model="dialog"
            max-width="290"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    small
            >
                <v-icon color="blue">mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                Update topic
            </v-card-title>
            <v-card-text>
                <form @submit.prevent="update">
                    <v-text-field
                            v-model="name"
                            label="Name"
                            required
                    ></v-text-field>
                    <v-btn
                            class="mr-4"
                            type="submit"
                            :disabled="!name"
                    >
                        Update
                    </v-btn>
                </form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import axios from 'axios'
    export default {
        props: ['topic'],
        data() {
            return {
                dialog: false,
                name: this.topic.name,
            }
        },
        methods: {
            update() {
                const url = 'admin/topics/'+this.topic.id;
                let data = {
                    name: this.name
                };
                axios.put(url, data).then(()=>{
                    this.$emit('updated');
                    this.dialog = false;
                })
            }
        }
    }
</script>