<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-sheet rounded="lg">
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-list color="transparent">

      <draggable
          v-model="topics"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <v-list-group
              :value="false"
              no-action
              v-for="topic in topics"
              :key="topic.name+topic.id"
          >
            <template v-slot:activator>
              <v-list-item-action class="flex-row">
                <v-btn small icon @click="deleteTopic(topic)">
                  <v-icon color="red lighten-1">mdi-delete</v-icon>
                </v-btn>
                <edit-topic :topic="topic" @updated="reloadTopics"></edit-topic>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ topic.name }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <draggable
                v-model="topic.contents"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
            >
              <v-list-item
                  v-for="content in topic.contents"
                  :key="content.id+content.id"
                  link
                  @click="pushToLink(content)"
              >
                <v-list-item-icon v-if="content.video">
                  <v-icon v-text="'mdi-video'"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-if="content.video" v-text="content.video.title"></v-list-item-title>

                <v-list-item-icon v-if="content.exam">
                  <v-icon v-text="'mdi-newspaper'"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-if="content.exam" v-text="content.exam.title"></v-list-item-title>

                <v-list-item-icon v-if="content.written_exam">
                  <v-icon v-text="'mdi-book-open'"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-if="content.written_exam" v-text="content.written_exam.title"></v-list-item-title>

                <v-list-item-icon v-if="content.pdf">
                  <v-icon v-text="'mdi-file-pdf'"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-if="content.pdf" v-text="content.pdf.title"></v-list-item-title>

                <v-list-item-icon v-if="content.note">
                  <v-icon v-text="'mdi-note-outline'"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-if="content.note" v-text="content.note.title"></v-list-item-title>

                <v-list-item-icon v-if="content.audio">
                  <v-icon v-text="'mdi-music'"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-if="content.audio" v-text="content.audio.title"></v-list-item-title>

                <v-list-item-action>
                  <v-btn icon @click="deleteContent(content)">
                    <v-icon color="red lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </draggable>
          </v-list-group>
        </transition-group>
      </draggable>

      <v-divider class="my-2"></v-divider>

      <v-list-item
          link
          color="grey lighten-4"
      >
        <form @submit.prevent="submit">
          <h3>Add new topic</h3>
          <v-text-field
              v-model="name"
              label="Name"
              required
          ></v-text-field>
          <v-btn
              class="mr-4"
              type="submit"
              :disabled="!name"
          >
            Add
          </v-btn>
        </form>
      </v-list-item>
    </v-list>
  </v-sheet>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import EditTopic from './EditTopic.vue'
import draggable from "vuedraggable"

export default {
  props: ['course'],
  components: {
    EditTopic,
    draggable
  },
  data() {
    return {
      name: '',
      topics: this.course.topics,
      snackbar: false,
      text: '',
      drag: false,
      color: 'blue',
    }
  },
  computed: {
    form: function () {
      return {
        name: this.name,
        course_id: this.course.id,
        order: 999999,
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  methods: {
    sortItems() {
      const url = 'admin/topics'
      let data = {
        topics: this.topics,
        type: 'sort'
      }
      axios.post(url, data).then(() => {
      })
    },
    pushToLink(content) {
      if (content.exam) {
        if (content.exam.mode === 'group_exam') {
          this.$router.push({name: 'InsertGroupMcq', params: {examId: content.exam.id}})
          return
        }
        this.$router.push({name: 'InsertMcq', params: {examId: content.exam.id}})
      }
      {
        if (content.written_exam) {
          this.$router.push({name: 'InsertQuestion',params:{writtenExamId: content.written_exam.id}})
        }
      }
      if (content.video) {
        this.$router.push({name: 'EditVideo', params: {videoId: content.video.id}})
      }
      if (content.note) {
        this.$router.push({name: 'EditNote', params: {noteId: content.note.id}})
      }
      if (content.pdf) {
        this.$router.push({name: 'EditPdf', params: {pdfId: content.pdf.id}})
      }
      if (content.audio) {
        this.$router.push({name: 'EditAudio', params: {audioId: content.audio.id}})
      }
    },
    submit() {
      const url = 'admin/topics'
      axios.post(url, this.form).then(() => {
        this.clear()
        this.color = 'green'
        this.snackbar = true
        this.text = 'Topic created'
        this.reloadTopics()
      }).catch(() => {
        this.clear()
        this.color = 'red'
        this.snackbar = true
        this.text = 'Error occurred'
        this.reloadTopics()
      })
    },
    deleteTopic(topic) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/topics/' + topic.id
          axios.delete(url).then(() => {
            this.reloadTopics()
            Swal.fire(
                'Deleted!',
                '',
                'success'
            )
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
                'warning'
            )
          })
        }
      })
    },
    deleteContent(content) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/contents/' + content.id
          axios.delete(url).then(() => {
            this.reloadTopics()
            Swal.fire(
                'Deleted!',
                '',
                'success'
            )
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
                'warning'
            )
          })
        }
      })
    },
    reloadTopics() {
      this.$emit('updated')
    },
    clear() {
      this.name = ''
    },
  },
  mounted() {

  },
  watch: {
    course() {
      this.topics = this.course.topics
    },
    topics: {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.sortItems()
        }
      },
      deep: true
    }
  }
}
</script>
<style>
.button {
  margin-top: 35px
}

.flip-list-move {
  transition: transform 0.5s
}

.no-move {
  transition: transform 0s
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb
}

.list-group {
  min-height: 20px
}

.list-group-item {
  cursor: move
}

.list-group-item i {
  cursor: pointer
}
</style>