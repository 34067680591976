<template>
  <v-sheet rounded="lg">

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Coupons</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-dialog
            v-model="dialog"
            max-width="400"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline" v-text="!editMode ? 'Add a new coupon' : 'Update coupon'">
            </v-card-title>
            <v-card-text>
              <form @submit.prevent="editMode ? update : submit">
                <v-text-field
                    v-model="code"
                    label="Code"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="discount"
                    label="Discount"
                    required
                ></v-text-field>
              </form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="green darken-1"
                  text
                  @click="editMode ? update() : submit()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list-item-action>
    </v-list-item>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Code
          </th>
          <th class="text-left">
            Discount
          </th>
          <th class="text-left">
            Actions
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="coupon in coupons"
            :key="coupon.id"
        >
          <td>{{ coupon.code }}</td>
          <td>{{ coupon.discount }}</td>
          <td>
            <v-btn
                small
                color="blue"
                icon
                @click="edit(coupon)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
                small
                color="red"
                icon
                @click="deleteItem(coupon)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-sheet>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "CreateCoupons",
  props: ['course'],
  data() {
    return {
      dialog: false,
      editMode: false,
      selectedItem: null,
      coupons: [],
      code: '',
      discount: '',
    }
  },
  mounted() {
    this.initialize()
  },
  computed: {
    form() {
      let formData = new FormData()
      if (this.editMode) {
        formData.append('_method', 'PUT')
      }
      if (!this.editMode) {
        formData.append('course_id', this.course.id)
      }
      formData.append('code', this.code)
      formData.append('discount', this.discount)
      return formData
    }
  },
  methods: {
    initialize() {
      const url = 'admin/coupons?course=' + this.course.id
      axios.get(url).then((response) => {
        this.coupons = response.data.coupons
      })
    },
    create() {
      this.reset()
    },
    edit(item) {
      this.dialog = true
      this.editMode = true
      this.selectedItem = item
      this.code = item.code
      this.discount = item.discount
    },
    submit() {
      const url = 'admin/coupons'
      axios.post(url, this.form).then(() => {
        this.initialize()
        this.dialog = false
      })
    },
    update() {
      const url = 'admin/coupons/' + this.selectedItem.id
      axios.post(url, this.form).then(() => {
        this.initialize()
        this.dialog = false
      })
    },
    deleteItem(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/coupons/' + item.id;
          axios.delete(url).then(() => {
            this.initialize();
            Swal.fire(
                'Deleted!',
                '',
                'success'
            )
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
                'warning'
            )
          })
        }
      });

    },
    reset() {
      this.editMode = false
      this.selectedItem = null
      this.code = ''
      this.discount = ''
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.reset()
      }
    }
  }
}
</script>

<style scoped>

</style>